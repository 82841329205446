/**
 * Open a popup to authenticate the user and then get a token.
 * @param authURL - The URL of the authentication page
 * @param redirectURL - The URL where the user will be redirected after authentication
 * @param parameters - The GET parameters to retrieve after authentication
 * @param popupTitle - The title of the authentication popup
 * @param width - The width of the popup
 * @param height - The height of the popup
 * @returns
 */
export async function authPopup(
  authURL: string,
  redirectURL: string,
  parameters: string[],
  popupTitle = "Authentication",
  width = 600,
  height = 600
): Promise<{ parameters: { [key: string]: string | null } }> {
  const promise = new Promise(function (resolve, reject) {
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;
    // Open popup to authenticate the user
    const popup = window.open(
      authURL,
      popupTitle,
      `width=${width},height=${height},top=${top},left=${left}`
    );
    const authInterval = window.setInterval(function () {
      try {
        if (popup != null && popup.location.href.startsWith(redirectURL)) {
          // User has been authenticated and redirected
          const getParameters = new URLSearchParams(popup.location.search);
          window.clearInterval(authInterval);
          popup.close();
          resolve({
            status: "ok",
            parameters: parameters.reduce(function (
              map: { [key: string]: string | null },
              parameter
            ) {
              map[parameter] = getParameters.get(parameter);
              return map;
            },
            {}),
          });
        }
      } catch (e) {
        // The user is still on the authentication page, so it's not possible to access popup properties
      }
      if (popup != null && popup.closed) {
        // The popup was closed by the user, before authentication was completed
        window.clearInterval(authInterval);
        reject({
          status: "error",
          message:
            "La popup a été fermée avant que la connexion soit terminée !",
        });
      }
    }, 100);
  });
  return promise as Promise<{ parameters: { [key: string]: string | null } }>;
}
