



























import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { MemoryGame } from "../memory";

@Component({
  computed: {
    ...mapState(["user"]),
  },
})
export default class Team extends Vue {
  leaderboard: unknown | null = null;

  created(): void {
    // Fetch leadboard from API
    fetch("/api/games").then((response) => {
      response.json().then((json) => {
        if (json.status === "success") {
          this.leaderboard = json.data.leaderboard;
        }
      });
    });
  }

  mounted(): void {
    const game = new MemoryGame();
    game.rearrangeCards();
    game.cards.forEach((card) => {
      card.addEventListener("click", () => {
        //game.flip.bind(game, card)
        game.flip(card);
      });
    });
  }
}
