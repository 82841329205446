import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Team from "../views/Team.vue";
import Program from "../views/Program.vue";
import Allos from "../views/Allos.vue";
import News from "../views/News.vue";
import Games from "../views/Games.vue";
import Partnerships from "../views/Partnerships.vue";
import Video from "../views/Video.vue";
import Login from "../views/Login.vue";

import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: false,
      title: "Accueil",
    },
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: {
      requiresAuth: false,
      title: "Équipe",
    },
  },
  /*{
    path: "/program",
    name: "Program",
    component: Program,
    meta: {
      requiresAuth: false,
      title: "Programme",
    },
  },*/
  {
    path: "/allos",
    name: "Allos",
    component: Allos,
    meta: {
      requiresAuth: false,
      title: "Allos",
    },
  },
  {
    path: "/news",
    name: "News",
    component: News,
    meta: {
      requiresAuth: true,
      title: "Actualités",
    },
  },
  {
    path: "/games",
    name: "Games",
    component: Games,
    meta: {
      requiresAuth: false,
      title: "Jeux",
    },
  },
  {
    path: "/partnerships",
    name: "Partnerships",
    component: Partnerships,
    meta: {
      requiresAuth: false,
      title: "Partenariats",
    },
  },
  {
    path: "/video",
    name: "Video",
    component: Video,
    meta: {
      requiresAuth: true,
      title: "Vidéo",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
      title: "Connexion",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Guard before routing to verify authentication and change page title
router.beforeEach((to, from, next) => {
  // Find the route that matches the current path and get its title from its meta data
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title + " • UNIV'EIRB";
  } else document.title = "UNIV'EIRB";

  // If the route requires authentication and the user is not logged in, redirect to the login page
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (to.path === "/logout") {
    store.dispatch("logout", true);
    next({
      name: "Home",
    });
  } else if (requiresAuth && !store.state.user) {
    next({
      name: "Login",
      query: { redirect: to.fullPath },
    });
  } else if (to.name === "Login" && store.state.user) {
    next({
      name: "Home",
    });
  } else if (from.name === "Video") {
    document.body.classList.remove("video-visible");
    document.body.classList.remove("video-loaded");
    setTimeout(() => {
      next();
    }, 500);
  } else {
    next();
  }
});

export default router;
