












import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

import YTPlayer from "yt-player";

@Component({
  computed: {
    ...mapState(["user"]),
  },
})
export default class Video extends Vue {
  time = "--:--:--";
  currentTime = "--:--:--";
  leftTime = "--:--:--";
  duration = 0;
  marquee = "        UNIVEIRB À LA VITESSE DE LA LUMI'EIRB !";
  player: unknown | null = null;
  intervalId: number | null = null;
  videoId: string | null = null;

  async created(): Promise<void> {
    await fetch("/api/video").then((response) => {
      response.json().then((json) => {
        if (json.status === "success") {
          this.videoId = json.data;
          const player = new YTPlayer("#player", {
            host: "https://www.youtube-nocookie.com",
            modestBranding: true,
          });

          player.load(json.data);
          player.setVolume(100);

          player.on("cued", () => {
            // The iframe has finished loading and is ready to be played
            document.body.classList.add("video-loaded");
            document.body.classList.add("video-visible");
            this.duration = player.getDuration();
          });

          player.on("timeupdate", (time: number) => {
            const hours = this.formatTime(Math.floor(time / 3600));
            const minutes = this.formatTime(Math.floor((time % 3600) / 60));
            const seconds = this.formatTime(Math.floor(time % 60));
            this.currentTime = `${hours}:${minutes}:${seconds}`;

            const leftTime = this.duration - time;
            const hoursLeft = this.formatTime(Math.floor(leftTime / 3600));
            const minutesLeft = this.formatTime(
              Math.floor((leftTime % 3600) / 60)
            );
            const secondsLeft = this.formatTime(Math.floor(leftTime % 60));
            this.leftTime = `${hoursLeft}:${minutesLeft}:${secondsLeft}`;
          });

          player.on("playing", () => {
            document.body.classList.add("video-playing");
          });

          player.on("paused", () => {
            document.body.classList.remove("video-playing");
          });

          player.on("ended", () => {
            document.body.classList.remove("video-playing");
          });
        } else {
          console.error(
            "Une erreur est survenue durant la récupération de la vidéo"
          );
        }
      });
    });
  }

  formatTime(time: number | string): number | string {
    if (time < 10) {
      return "0" + time;
    }
    return time;
  }

  mounted(): void {
    var scrollingElement = document.scrollingElement || document.body;
    scrollingElement.scrollTop = scrollingElement.scrollHeight;

    function updateTime(self: Video) {
      const date = new Date();

      const hour = self.formatTime(date.getHours());
      const minutes = self.formatTime(date.getMinutes());
      const seconds = self.formatTime(date.getSeconds());

      self.time = `${hour}:${minutes}:${seconds}`;
      self.marquee = self.marquee.substr(1) + self.marquee.substr(0, 1);
    }

    this.intervalId = setInterval(
      (function (self) {
        return function () {
          updateTime(self);
        };
      })(this),
      200
    );
  }

  destroyed(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
