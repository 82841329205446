import Vue from "vue";
import Vuex from "vuex";

import * as utils from "../utils";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async fetchUser({ commit }) {
      await fetch("/api/auth/user")
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "success") {
            commit("setUser", json.data);
          }
        });
    },
    login({ commit }, { token, ticket, callback }): Promise<unknown> {
      return fetch(`/api/auth/verify?token=${token}&ticket=${ticket}`)
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "success") {
            commit("setUser", json.data);
            callback();
            return Promise.resolve();
          } else {
            if (json.code === "USER_NOT_AUTHORIZED") {
              return Promise.reject({
                status: "error",
                message: "Vous n'êtes pas autorisé à vous connecter !",
              });
            } else {
              return Promise.reject({
                status: "error",
                message: "Une erreur est survenue !",
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    popupLogin({ dispatch }, { callback }): Promise<unknown> {
      const redirectUrl = window.location.origin + "/api/auth/success";
      return utils
        .authPopup(
          `/api/auth/redirect?redirectUrl=${encodeURIComponent(redirectUrl)}`,
          redirectUrl,
          ["ticket", "token"]
        )
        .then((result) => {
          return dispatch("login", { ...result.parameters, callback });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    logout({ commit }): Promise<unknown> {
      return fetch("/api/auth/logout")
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "success") {
            commit("setUser", null);
            return Promise.resolve();
          }
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
  modules: {},
});
