






























import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState(["user"]),
  },
})
export default class News extends Vue {
  news: unknown | null = null;

  mounted(): void {
    fetch("/api/news")
      .then((response) => response.json())
      .then((json) => {
        this.news = json.data;
      });
  }

  getDateTime(time: number): string {
    const date = new Date(time * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `le ${("0" + day).slice(-2)}/${("0" + month).slice(-2)}/${year} à ${(
      "0" + hours
    ).slice(-2)}h${("0" + minutes).slice(-2)}`;
  }
}
