






























import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

import partnerships from "../assets/partnerships.json";

@Component({
  computed: {
    ...mapState(["user"]),
  },
})
export default class Team extends Vue {
  partnerships = partnerships;

  info(partnership: any): void {
    if (partnership.name == "Chibre Bleu") {
      alert(
        "Utilisez le code 'UNV10' pour bénéficier de 10% de réduction sur votre commande"
      );
      window.open(partnership.link, "_blank");
    }
  }
}
