






















































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

import poles from "../assets/members.json";

const polesNames = {
  bureau: "Pilotes",
  com: "Messagers",
  event: "Techniciens",
  log: "Logisticiens",
  part: "Ravitailleurs",
};

const groups = {
  info: "Informatique",
  elec: "Électronique",
  matmeca: "Matméca",
  telecom: "Télécom",
  rsi: "RSI",
  see: "SEE",
};

@Component({
  computed: {
    ...mapState(["user"]),
  },
})
export default class Team extends Vue {
  poles = poles;
  polesNames = polesNames;
  groups = groups;

  toggleCard(username: string): void {
    const card = document.getElementById(username);
    if (card) {
      card.classList.toggle("flipped");
    }
  }
}
