







import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState(["user"]),
  },
})
export default class Login extends Vue {
  async login(): Promise<void> {
    this.$store
      .dispatch("popupLogin", {
        callback: () => {
          this.$router.push(this.$route.query.redirect.toString() || "/");
        },
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
