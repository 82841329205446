

















import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState(["user"]),
  },
})
export default class Home extends Vue {
  brasse = `[BRASSE] [UNIV'EIRB]

Notre histoire commence il y a longtemps, très longtemps⏳… À une époque sombre et froide dont personne (Personne ?) ne peut témoigner. En effet, il est difficile de savoir ce qui se tramait dans les confins de cet espace vaste et plein de surprises qui s'appellerait plus tard l'Univ’eirb ✨.

La théorie la plus largement connue est celle d'un grand BOUM 💥 duquel notre univers aurait émergé.
Imaginez un peu, une explosion originelle si puissante que notre univers est encore en expansion quelque 13,8 milliards d'années plus tard ↕️↔️.

Ridicule me direz vous 🤨? En effet… 

Laissez-nous vous conter une toute autre histoire.. Non, l'Histoire avec un grand H: l’unique, la vraie ☄️ !

Le centre de notre Univers actuel est l'ENSEIRB-MATMECA, depuis la nuit des temps, des êtres extra-sensoriels doués d'une conscience omnisciente et omnipotente y siègent 🧘… Après un chaos qui secoua dans tous ses confins ce lieu, un groupe de 33 super-consciences décidèrent de prendre le relai après le chaos du far west qui avait chassé la menace invisible 😷🔫.

C'est ainsi qu'à l'an 0 à 00h00 (et quelques secondes) une étincelle ✨ émergea de la super-conscience connue maintenant sous le nom de Louis : "Et si nous inventions quelque chose de grand, si grand qu'il serait impossible d'en voir, d'en imaginer ou même d'en conceptualiser les confins et qui grandirait plus vite que la lumière" 🌌.
Cette idée fit le tour des super-consciences à une vitesse si démesurément grande qu'une énergie en naquit 💫. De plus, il semblait que chacune fut consciente de sa place, de son rôle dans ce projet interstellaire.
L'idée devint alors matière et 7 factions alliées émergèrent en même temps que l'Univ’eirb 🌟.

🎊🎉 L'event-universel avec à sa tête Camille, Jolann et Malo qui avait pour rôle d'assurer que personne ne puisse s'ennuyer autant qu'avant cette re-naissance. Ils furent aidés par des êtres tout aussi clès: Amandine, Cassandra, Clément, Florian, Mathéo et Tanguy. À leurs côtés pour éviter qu’il ne meurent de soif, ils furent ravitaillés par le divin Nicolas🥤, et enfin pour prévenir toutes blessures qui pourraient ralentir l’expansion de leur territoire, le sage Lou serait présent 🚑.

📋📦 La log-spatiale pensée par Antoine et construite par Arnaud, Luca, Moumoune et Simo qui devint l'entité à l'origine même du matériel tel que les molécules ⚛️ ou bien les ciseaux MAPED pour gauchers ✂️. Mathilde elle aussi veillerait sur le bien-être sans distinctions des occupants de l’univeirb et à se battre pour des choses qui devraient être normales et acquises près de 2022 milliards d'années après la création originelle 🔝👫.

📡👨‍💻 La cosmonication menée par Alban et diffusée par ses relais lasers Alexandre, Anas, Baptiste (maître du temps par ailleurs), Jules et Ziad qui est non sans raison la plus populaire des factions et dont le rôle fut dès la genèse d'être l'intermédiaire entre toutes les consciences existantes et à venir 🗣️. Titouan veillerait enfin à ce que leur histoire soit retransmise sur tous les écrans vidéos 🎥.

🤝💸 La Part-icule Primaire leadée par Victorine et le très jeune Vincent décidèrent de chercher du soutien parmis des entités plus grandes encore. 

🌳♻️ Le Développement Du Variable représenté par Florian se donna comme mission d'éviter que l'Univers ne devienne une déchetterie et reste un espace sain ou tout le monde s’y sentirait à l’aise 🤩.

🔚 Enfin, pour permettre une cohésion totale, Louis-Le-Moyen décida de créer à l'aide de ses plus fidèles super-consciences, une instance toute puissante et directrice 🌠. À ses plus proches côtés, Elena et Mathieu (maître des éléments) veillèrent à ce que cet espace reste équilibré ⚖️. Arthur et Julie assurèrent l’égale répartition des tâches dans cet espace sans limites 📆, enfin Thomas et son associé Jules garantirent un équilibre prospère pour cet espace fourmillant déjà de vie 🧾🧮.

Restait une dernière chose à faire, durant 2022 milliards d'années durant lesquelles nos 33 consciences, devinrent matières et accessoirement beaucoup moins omniscientes et omnipotentes que dans toute notre histoire (mais toujours super quand même), réfléchirent à un nom pour réunir ces maisons censées dirigées cet espace infini. 

C'est ainsi que naquît… 

<center>
☀️
🌍🌎🌏
🪐✨UNIVEIRB✨🪐
🌍🌎🌏
🌕
</center>


Malheureusement cet équilibre prospère est menacée par un danger provenant des confins de l'au delà de l'observable 🔭 et nous avons besoin de vous enseirbiens pour contrer cette menace ! 📡🔢⚡💻📶💾


PS: Ravis de vous présenter UNV
PPS: Rejoignez notre telegram pour rien rater:
PPPS: Et notre Instagram (askip ya un filtre bien stylé):
PPPPS: Vous pouvez également visiter notre site internet : 
PPPPPS: Plus vite que la lumi’eirb !
PPPPPPS: Et allez c’est parti pour les allos tous à vos tels !!!
PPPPPPPS: La carte est dispo partout.`;
}
