import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

(async function () {
  // Check if the user is already logged in
  await store.dispatch("fetchUser");

  // Then start the app
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
})();
