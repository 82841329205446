const emojis = [
  "🌟",
  "🚀",
  "🌙",
  "👾",
  "👽",
  "👨‍🚀",
  "🌌",
  "📡",
  "🌠",
  "🔭",
  "🛸",
  "🪐",
  "👶",
];

export class MemoryGame {
  cardsContainer: HTMLElement | null;
  cards: HTMLElement[];
  flippedCards: HTMLElement[];
  delay: number;
  appContainer: HTMLElement | null;

  constructor() {
    this.cardsContainer = document.querySelector(".memory");
    this.cards = Array.from(document.querySelectorAll(".card"));
    this.flippedCards = [];
    this.delay = 1000;
    this.appContainer = document.querySelector("#app");
  }

  rearrangeCards(): void {
    this.cards = this.cards.sort(() => 0.5 - Math.random());
    let cardsPaired = 0;
    this.cards.forEach((card) => {
      const pair = cardsPaired - (cardsPaired % 2);
      card.dataset.pair = pair.toString();
      const emojiContainer = card.querySelector(".face:last-child .emoji");
      if (emojiContainer) {
        emojiContainer.innerHTML = emojis[pair / 2];
      }
      cardsPaired++;
    });
  }

  validateCards(): void {
    const [firstCard, secondCard] = this.flippedCards;

    this.cardsContainer?.classList.add("no-event");

    if (
      firstCard != secondCard &&
      firstCard.dataset.pair === secondCard.dataset.pair
    ) {
      firstCard.classList.replace("flipped", "has-match");
      secondCard.classList.replace("flipped", "has-match");

      this.flippedCards = [];

      setTimeout(() => {
        const allHaveMatches = this.cards.every((card) =>
          card.classList.contains("has-match")
        );

        this.cardsContainer?.classList.remove("no-event");

        if (allHaveMatches) {
          this.rearrangeCards();
        }
      }, this.delay);
    } else {
      setTimeout(() => {
        firstCard.classList.remove("flipped");
        secondCard.classList.remove("flipped");

        this.flippedCards = [];

        this.cardsContainer?.classList.remove("no-event");
      }, this.delay);
    }
  }

  createOscar(x: number, y: number): void {
    if (this.appContainer) {
      const star = document.createElement("div");
      star.classList.add("oscar");
      star.style.left = `calc(${x}px + 64vw)`;
      star.style.top = `calc(${
        y + (this.appContainer.scrollTop || 0) - 140
      }px - 64vw)`;
      this.appContainer.appendChild(star);
      // Delete oscar after 12s
      setTimeout(() => {
        star.remove();
      }, 12100);
    }
  }

  flip(selectedCard: HTMLElement): void {
    selectedCard.classList.add("flipped");

    if (selectedCard.dataset.pair === "24") {
      //const x = Math.floor(Math.random() * (window.innerWidth - 100));
      const x = window.innerWidth - 100;
      const y = Math.floor(
        Math.random() * (window.innerHeight / 2) + window.innerHeight / 2
      );
      this.createOscar(x, y);
    }

    this.flippedCards.push(selectedCard);

    if (this.flippedCards.length === 2) {
      this.validateCards();
    } else if (
      this.cards.filter((c) => c.classList.contains("has-match")).length ===
        this.cards.length - 1 &&
      selectedCard.dataset.pair === "24"
    ) {
      this.cards.forEach((c) => c.classList.remove("flipped", "has-match"));
      this.rearrangeCards();
      this.flippedCards = [];
    }
  }
}
