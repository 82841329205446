



































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState(["user"]),
  },
})
export default class Home extends Vue {
  animationsLoaded = false;
  appContainer: HTMLElement | null = null;
  menuBtn: HTMLInputElement | null = null;

  layers: HTMLImageElement[] = [];

  shootingStars: HTMLDivElement[] = [];
  shiningStars: HTMLDivElement[] = [];

  currentShootingStar = 0;
  currentShiningStar = 0;

  offset = 0;

  @Watch("$route")
  onPropertyChanged(value: Route): void {
    if (this.menuBtn) {
      this.menuBtn.checked = false;
    }
    this.navigationParallax(value?.name);
  }

  navigationParallax(routeName: string | null | undefined): void {
    const routes = [
      "Home",
      "Team",
      "Program",
      "Allos",
      "News",
      "Games",
      "Partnerships",
      "Video",
    ];

    if (routeName && routes.includes(routeName)) {
      this.offset = routes.indexOf(routeName) * 15 - 45;
    }
  }

  createShootingStar(
    x: number,
    y: number,
    variation: string,
    save = true
  ): void {
    if (this.appContainer) {
      const star = document.createElement("div");
      star.classList.add("shooting-star", variation);
      star.style.left = `calc(${x}px + 64vw)`;
      star.style.top = `calc(${
        y + (this.appContainer?.scrollTop || 0) - 140
      }px - 64vw)`;
      this.appContainer.appendChild(star);
      if (save) {
        this.shootingStars.push(star);
      } else {
        setTimeout(() => {
          star.remove();
        }, 1600);
      }
    }
  }

  createOscar(x: number, y: number): void {
    if (this.appContainer) {
      const star = document.createElement("div");
      star.classList.add("oscar");
      star.style.left = `calc(${x}px + 64vw)`;
      star.style.top = `calc(${
        y + (this.appContainer.scrollTop || 0) - 140
      }px - 64vw)`;
      this.appContainer.appendChild(star);
      // Delete oscar after 12s
      setTimeout(() => {
        star.remove();
      }, 12100);
    }
  }

  createShiningStar(x: number, y: number): void {
    if (this.appContainer) {
      const star = document.createElement("div");
      star.classList.add("star");
      star.style.left = `${x - 40}px`;
      star.style.top = `${y - 40 + (this.appContainer.scrollTop || 0)}px`;
      this.appContainer.appendChild(star);
      this.shiningStars.push(star);
    }
  }

  moveLayers(mouseX: number, mouseY: number): void {
    this.layers.forEach((layer, index) => {
      layer.style.transform = `translateX(${
        (mouseX - window.innerWidth / 2) * 0.015 * (1 + index)
      }px) translateY(${
        (mouseY - window.innerHeight / 2) * 0.015 * (1 + index)
      }px)`;
    });
  }

  mounted(): void {
    console.log(
      "%cVotez UNV ! 💙🤍",
      "color: cyan; font-size: 56px; font-weight: semi-bold"
    );
    console.log(
      "%c(et arrête de farfouiller dans la console le hacker en eirb)",
      "color: red; font-size: 16px; font-weight: bold"
    );
    console.log(`${window.location.origin}/api/s3cr3t-3ndp01nt`);

    this.appContainer = document.querySelector("#app");
    this.menuBtn = document.querySelector("#menu-btn");
    this.navigationParallax(this.$route.name);

    if (!this.animationsLoaded) {
      // Get the 4 moving layers
      this.layers = Array.from(
        document.querySelectorAll(".layer4 img")
      ) as HTMLImageElement[];

      // If not on mobile device, create beautiful shooting stars !
      if (
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // Move layers at mouse position
        document.addEventListener("mousemove", (e) => {
          this.moveLayers(e.clientX, e.clientY);
        });

        // Create a shooting star at mouse click
        document.addEventListener("click", (e) => {
          this.createShootingStar(e.clientX, e.clientY, "star1", false);
        });

        // Every 0.5s, create a shooting star at random position or modify an existing one
        setInterval(() => {
          const x = Math.floor(Math.random() * (window.innerWidth - 100));
          const y = Math.floor(Math.random() * (window.innerHeight - 100));
          const variation = `star${Math.floor(Math.random() * 2) + 1}`;
          if (this.shootingStars.length < 3) {
            this.createShootingStar(x, y, variation);
          } else {
            const star = this.shootingStars[this.currentShootingStar];
            star.style.display = "none";
            star.classList.remove("star1", "star2");
            star.classList.add(variation);
            star.style.left = `calc(${x}px + 64vw)`;
            star.style.top = `calc(${
              y + (this.appContainer?.scrollTop || 0) - 140
            }px - 64vw)`;
            star.style.display = "block";
            this.currentShootingStar =
              (this.currentShootingStar + 1) % this.shootingStars.length;
          }
        }, 500);
      }

      // Every 250ms, create a shining star at random position or modify an existing one
      setInterval(() => {
        const x = Math.floor(Math.random() * (window.innerWidth - 100));
        const y = Math.floor(Math.random() * (window.innerHeight - 100));
        if (this.shiningStars.length < 4) {
          this.createShiningStar(x, y);
        } else {
          const star = this.shiningStars[this.currentShiningStar];
          star.style.display = "none";
          star.style.left = `${x - 40}px`;
          star.style.top = `${y - 40 + (this.appContainer?.scrollTop || 0)}px`;
          star.style.display = "block";
          this.currentShiningStar =
            (this.currentShiningStar + 1) % this.shiningStars.length;
        }
      }, 250);
    }

    this.animationsLoaded = true;
  }

  logout(): void {
    this.$store.dispatch("logout").then(() => {
      const app = document.getElementById("app");
      if (app) {
        app.scrollTo({ top: 0, behavior: "smooth" });
        this.$router.push("/");
      }
    });
  }
}
